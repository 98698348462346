














































import Vue from "vue";
import SignupButton from "@/components/SignupButton.vue";

export default Vue.extend({
  components: {
    SignupButton,
  },
  props: {},
  data() {
    return {};
  },
});
